@tailwind base;
@tailwind components;
@tailwind utilities;

.website_custom_color_light {
  background: linear-gradient(180deg, #dfedf9 0%, #f0f7fb 72%);
}

.website_custom_color_dark {
    background: linear-gradient(180deg, #2C3E50 0%, #34495E 72%);
}

.translate-z {
  transform: translateZ(20px);
}

@keyframes infinite-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-infinite-scroll {
  display: flex;
  animation: infinite-scroll 20s linear infinite;
}
.website-custom-text {
  background: linear-gradient(to right, #0152FE, #028BFA);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1.2; /* Increase line height */
  overflow: visible;
  white-space: normal;
}

.website-custom-color {
  background: linear-gradient(to right, #0152FE, #028BFA);
}

